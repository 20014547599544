.coin-buy-bg {
    background-color: #43424275;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 50px;
    left: 0;
}

.coin-buy-title {
    font-size: 18px;
    color: #333333;
    text-align: center;
    padding: 10px 0;
}

.coin-buy-content {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    padding: 0 10px;
    margin: 50px auto;
    background-color: #ffffff;

}

.coin-buy-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.coin-buy-list .coin-buy-item {
    width: 30%;
    height: 80px;
    background-color: #dcdcf6;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #333333;
    margin-bottom: 15px;
}

.coin-buy-list .coin-buy-item-select {
    background-color: #8e8eef;
    color: #ffffff;
    color: #333333;
}

.coin-buy-btn {
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
}