* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
}




/**覆盖默认样式**/



.lawyer-page .adm-list-item-content-main {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7B8193;
}

.lawyer-page .adm-list-item-content-extra {
    font-size: 1.4rem;
    font-weight: 400;
    color: #1D2544;
    line-height: 2rem;
}


/**tab start**/
.lawyer-page .adm-tabs-header {
    border: 0px;
}


.lawyer-page .adm-list {
    margin-top: 1rem;
}


.lawyer-page .adm-tabs-content {
    padding: 0px;
}


.lawyer-page .adm-list-body {
    background-color: inherit;
    border: 0px;
    overflow: visible;
}

.lawyer-page .adm-tabs-tab-active {
    color: #10A37F;
}

.lawyer-page .adm-tabs-tab-line {
    background: #10A37F;
}

/* .lawyer-page .adm-button {
    --background-color: #10A37F;
    --border-color: var(--background-color)
} */

/**tab end**/

.lawyer-page {
    min-height: 100vh;
    background: #F7F8FA;
}

.lawyer-page .font-13 {
    font-size: 1.3rem;
}

.lawyer-page .font-25 {
    font-size: 2.5rem;
}

.lawyer-page .font-40 {
    font-size: 4rem;
}


.lawyer-page .font-30 {
    font-size: 3rem;
}


.lawyer-page .w5 {
    width: 50%;
}

.lawyer-page .gray-tag {
    display: inline-block;
    background: #F6F6F6;
    border-radius: 0.9rem;
    font-size: 1rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 1.8rem;
    padding: 0.2rem 0.8rem;
}


.lawyer-page .green-tag {
    display: inline-block;
    background: #F4FFFC;
    border-radius: 0.9rem;
    font-size: 1rem;
    font-weight: 400;
    color: #10A37F;
    line-height: 1.8rem;
    padding: 0 0.8rem;
}


.lawyer-page .mr-5 {
    margin-right: 0.5rem;
}

.lawyer-page .mt-4 {
    margin-top: 0.4rem;
}

.lawyer-page .mt-10 {
    margin-top: 1rem;
}

.lawyer-page .mt-20 {
    margin-top: 2rem;
}

.lawyer-page .ml-5 {
    margin-left: 0.5rem;
}

.lawyer-page .ml-40 {
    margin-left: 4rem;
}

.lawyer-page .mb-10 {
    margin-bottom: 1rem;
}

.lawyer-page .pd-10 {
    padding: 1rem;
}

.lawyer-page .fw-500 {
    font-weight: 500;
}

.lawyer-page .color-red {
    color: #FF4D4F;
}

.lawyer-page .color-green {
    color: #10A37F;
}

/* 
.adm-modal-footer .adm-modal-button {
    background-color: #10A37F
} */

.lawyer-page .like-icon {
    background: url(/src/pages/lawyer/icon/like.png) no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
}

.lawyer-page .comment-icon {
    background: url(/src/pages/lawyer/icon/comment.png) no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
}


.lawyer-page .share-icon {
    background: url(/src/pages/lawyer/icon/share.png) no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
}


.lawyer-page .add-img-icon {
    background: url(/src/pages/lawyer/icon/add-img.png) no-repeat;
    background-size: contain;
    width: 2rem;
    height: 2rem;
}

.lawyer-page .add-news-icon {
    background: url(/src/pages/lawyer/icon/add-news.png) no-repeat;
    background-size: contain;
    width: 2rem;
    height: 2rem;
}

.lawyer-page .location-icon {
    background: url(/src/pages/lawyer/icon/location.png) no-repeat;
    background-size: contain;
    width: 1.3rem;
    height: 1.3rem;
}

.lawyer-page .icon-date {
    background: url(/src/pages/lawyer/icon/date.png) no-repeat;
    background-size: contain;
    width: 1.3rem;
    height: 1.3rem;
}




.lawyer-page .add-icon {
    background: url(/src/pages/lawyer/icon/add.png) no-repeat;
    background-size: contain;
    width: 4.3rem;
    height: 4.3rem;
}



.lawyer-page .btn-submit {
    width: 20rem;
    height: 3.7rem;
    background: #10A37F;
    border-radius: 1.85rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 3.7rem;
    border: 0px;
    padding: 0;
}

.lawyer-page .btn-delete {
    width: 20rem;
    height: 3.7rem;
    background: none;
    color: #353A44;
    text-align: center;
    line-height: 3.7rem;
    border: 0px;
}






.lawyer-page .share-canvas-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 2000;
    background-color: #00000055;
    padding: 1rem;
}

.lawyer-page .share-canvas {
    background-color: #ffffff;
    display: none;
    width: 100%;
}

.lawyer-page .share-btn-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    padding: 3rem;
    margin-top: 1rem;
    border-radius: 1rem;
    position: relative;
    /* color: #10A37F; */
}

.lawyer-page .share-btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    background-size: contain;
}


.lh-40 {
    line-height: 4rem;
}

.ml-20 {
    margin-left: 2rem;
}

.ailawyer-page {
    overflow: hidden;
}

.ailawyer-page .robot-item {
    background-color: #F8FBFF;
}


.ailawyer-page .robot-item .t1 {
    border-bottom: 1px solid #EAEBF0;
    padding: 10px 20px;
}


.ailawyer-page .robot-item .t2 {
    padding: 10px 20px;
    font-size: 12px;
}




/* .protocol-content .protocol-submit button {
    width: 100%;
    line-height: 40px;
    background-color: #4A90E2;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    font-size: 16px;
} */

.text-right .adm-input-element {
    text-align: right;
}




.ql-editor {
    min-height: 200px !important;
}


#float-page {
    position: fixed;
    top: 0px;
    z-index: 99999;
    background-color: #fff;
    display: none;
    height: 100vh;
    overflow: auto;
}

.content-space-pre-line {
    white-space: pre-wrap !important;
}

.no-scroll {
    overflow: hidden;
}

.scroll-body-page {
    height: calc(100vh - 60px);
    overflow: auto;
}


.btn-protocol-order-submit {
    position: fixed;
    bottom: 2rem;
    width: 20rem;
    height: 3.7rem;
    background: #10A37F;
    border-radius: 1.85rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 3.7rem;
    border: 0px;
    padding: 0;
}