.lawyer-page .lawyer-item {
    margin-bottom: 2.4rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
    padding: 1.5rem;
}

.lawyer-page .lawyer-item .t1 {
    display: flex;
    flex-direction: column;
    /* padding: 0.5rem 1.5rem; */
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
}

.lawyer-page .lawyer-item .t1 .headimg {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: relative;
    top: -2rem;
}

.lawyer-page .lawyer-item .t1 .s1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5D5E5F;
    line-height: 1.65rem;
    height: 1.65rem;
}



.lawyer-page .lawyer-item .t1 .b {
    font-size: 1.3rem;
    font-weight: 500;
    color: #111111;
    line-height: 1.55rem;
}

.lawyer-page .lawyer-item .t1 .s2 {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;
    font-weight: 500;
    color: #1D2544;
    line-height: 2.5rem;
    /* margin: 0.8rem; */
    margin-top: 2rem;

}

.lawyer-page .lawyer-item .t1 .s2 .s {
    background: #EAF3F1;
    border-radius: 0.95rem;
    font-size: 1rem;
    color: #10A37F;
    display: flex;
    align-items: center;
    line-height: 2.2rem;
    padding: 0.3px 0.8rem;
    margin-left: 0.8rem;
}

.lawyer-page .lawyer-item .t1 .s3 {
    font-size: 1.3rem;
    font-weight: 400;
    color: #999999;
    line-height: 1.85rem;
    margin-top: 1.3rem;
}