.mine-protocol-page {
    padding: 1rem;
}

.mine-protocol-page .protocol-item {
    background: #FFFFFF;
    box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.8rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.mine-protocol-page .protocol-item .headimg {
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 1.5rem;
}



.mine-protocol-page .protocol-item .protocol-icon {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAF4F2;
    border-radius: 0.8rem;
}

.mine-protocol-page .protocol-item .protocol-icon svg {
    width: 2rem;
    height: 2rem;
}

.mine-protocol-page .protocol-item .protocol-icon .st1 {
    color: #10A37F;
    fill: #10A37F
}

.mine-protocol-page .protocol-item .protocol-icon .st2 {
    fill: #2DB291;
}

.mine-protocol-page .protocol-item .protocol-icon .st3 {
    fill: #FFFFFF;
}

.mine-protocol-page .protocol-item .protocol-icon .st4 {
    fill: #10A37F;
}


.mine-protocol-page .protocol-item .item-status {
    background: #FFFFFF;
    border-radius: 1.2rem;
    border: 0.05rem solid #7B8193;
    font-size: 1.2rem;
    font-weight: 400;
    color: #7B8193;
    line-height: 2.4rem;
    padding: 0 1.2rem;
    letter-spacing: 1px;
}


.mine-protocol-page .protocol-item .status-finish {
    border: 0.05rem solid #10A37F;
    color: #10A37F;
}

.mine-protocol-page .protocol-item .status-making {
    border: 0.05rem solid #FF9900;
    color: #FF9900;
}