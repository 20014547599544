.lawyer-chat-page {
    background: #F7F8FA;
    padding: 1.5rem;
    height: 100vh;
}

.lawyer-chat-page .adm-list-body {
    background-color: inherit;
    border: 0px;
}


.lawyer-chat-page .chat-item {
    margin-top: 2.5rem;
}


.lawyer-chat-page .chat-item .headimg {
    width: 5rem;
    height: 5rem;
    margin-right: 1.2rem;
}

.lawyer-chat-page .chat-item .s1 {
    font-size: 1.6rem;
    color: #121317;
    line-height: 2.25rem;
}

.lawyer-chat-page .chat-item .s2 {
    font-size: 1.4rem;
    line-height: 2.25rem;
    font-weight: 400;
    color: #8E8E8E;
    line-height: 2rem;
    margin-top: 0.4rem;
}

.lawyer-chat-page .chat-item .s3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #8E8E8E;
    line-height: 1.65rem;
}

.lawyer-chat-page .chat-item .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: -6rem;
    background: red;
    color: white;
    border: none;
    border-radius: 0rem 1rem 1rem 0rem;
    padding: 0.5rem 2rem;
}