.lawyer-detail-page {
    background: #F7F8FA;
    padding: 3rem 1.5rem;
    height: 100vh;
}

.lawyer-detail-page .adm-tabs-header {
    border: 0px;
}

.lawyer-detail-page .adm-tabs-tab-active {
    color: #10A37F;
}

.lawyer-detail-page .adm-tabs-tab-line {
    background: #10A37F;
}

.lawyer-detail-page .adm-tabs-content {
    padding: 0px;
}


.lawyer-detail-page .adm-list {
    margin-top: 1rem;
    border-radius: 0.6rem;
}

.lawyer-detail-page .adm-list-item-content {
    border-top: 0px;
}






.lawyer-detail-page .lawyer-item .t2 {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
}

.lawyer-detail-page .lawyer-item .t2 .price-wraper {
    font-size: 1.3rem;
    color: #FF5300;
}

.lawyer-detail-page .lawyer-item .t2 .price {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.85rem;
}

.lawyer-detail-page .lawyer-item .t2 .create-btn {
    width: 6rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border: 0.05rem solid #10A37F;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
}

.lawyer-detail-page .intro {
    background: #FFFFFF;
    border-radius: 0.8rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 2.2rem;
    margin-top: 1rem;
    padding: 1.7rem 1.5rem;
}

.lawyer-detail-page .intro .title {
    font-size: 1.3rem;
    font-weight: 500;
    color: #333333;
    line-height: 1.85rem;
    margin-bottom: 1.5rem;
}



.lawyer-detail-page .protocol-item {
    background: #FFFFFF;
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
    margin-top: 1rem;
    padding: 1.5rem;
}


.lawyer-detail-page .protocol-item .t1 {
    margin-bottom: 2rem;
}

.lawyer-detail-page .protocol-item .t2 {
    border-bottom: 1px solid #EEEEEE;
    padding: 1rem 0rem 1rem 0rem;
}

.lawyer-detail-page .protocol-item .t3 {
    border-top: 1px solid #EEEEEE;
    padding-top: 1rem;
}


.lawyer-detail-page .protocol-item .t3 .price-wraper {
    font-size: 1.6rem;
    color: #FF5300;
}

.lawyer-detail-page .protocol-item .t3 .price {
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.85rem;
}


.lawyer-detail-page .protocol-item .t3 .create-btn {
    width: 6rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border: 0.05rem solid #10A37F;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
}


.lawyer-detail-page .protocol-item .protocol-icon {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAF4F2;
    border-radius: 0.8rem;
}

.lawyer-detail-page .protocol-item .protocol-icon svg {
    width: 2rem;
    height: 2rem;
}

.lawyer-detail-page .protocol-item .protocol-icon .st1 {
    color: #10A37F;
    fill: #10A37F
}

.lawyer-detail-page .protocol-item .protocol-icon .st2 {
    fill: #2DB291;
}

.lawyer-detail-page .protocol-item .protocol-icon .st3 {
    fill: #FFFFFF;
}

.lawyer-detail-page .protocol-item .protocol-icon .st4 {
    fill: #10A37F;
}

.lawyer-detail-page .lawyer-news {
    padding: 2rem 3rem;
}