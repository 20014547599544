.mine-lawyer-protocol-order-page {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
}


.mine-lawyer-protocol-order-page .order-item {
    margin-bottom: 1rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
    padding: 1.5rem 1.5rem;
}

.mine-lawyer-protocol-order-page .order-item .headimg {
    width: 3.8rem;
    height: 3.8rem;
}


.mine-lawyer-protocol-order-page .order-item .item-status {
    background: #FFFFFF;
    border-radius: 1.2rem;
    border: 0.05rem solid #7B8193;
    font-size: 1.2rem;
    font-weight: 400;
    color: #7B8193;
    line-height: 2.4rem;
    padding: 0 1.2rem;
    letter-spacing: 1px;
}

.mine-lawyer-protocol-order-page .order-item .status-finish {
    border: 0.05rem solid #10A37F;
    color: #10A37F;
}

.mine-lawyer-protocol-order-page .order-item .status-making {
    border: 0.05rem solid #FF9900;
    color: #FF9900;
}