.dropdown-head {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
}

.dropdown-body {
    margin: 42px 0px 0px 0px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 2rem;
    padding-top: 0.56rem;
    z-index: 100;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-between {
    justify-content: space-between;
}



.flex-justify-around {
    justify-content: space-around;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}


.font-26 {
    font-size: 26px;
}

.font-30 {
    font-size: 30px;
}

.font-50 {
    font-size: 50px;
}

.font-bold {
    font-weight: bold;
}

.mtb-10 {
    margin: 10px 0px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.c-gray {
    color: #6b6a6b;
}

.pd-10 {
    padding: 10px;
}

.pd-lr-10 {
    padding: 0px 10px;
}

.pd-tb-15 {
    padding: 15px 0px;
}

.pd-tb-20 {
    padding: 20px 0px;
}

.pd-10-20 {
    padding: 10px 20px;
}

.pd-5-20 {
    padding: 5px 20px;
}


.lh-26 {
    line-height: 26px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.float-right {
    float: right;
}

.color-red {
    color: #cf3d3d;
}

.pd-20 {
    padding: 20px;
}

.btn-dashed {
    border: 1px dashed #6b6a6b;
}

.btn-gray {
    background-color: #f5f5f5;
    color: #6b6a6b;
}

.clear-both {
    clear: both;
}

.line-hr {
    height: 1px;
    width: 100%;
    border-top: 1px solid #e8e6e8;
    margin: 10px 0px;
}


.personal-points .p1 {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    /* background-color: #97a9ef; */
    width: 100%;
    text-align: center;
}

.adm-tab-bar-item-icon {
    height: 30px;
}

.paifu-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.project-icon {
    background: url(../asserts/icon/home.png) no-repeat;
    background-size: contain;
}

.adm-tab-bar-item-active .project-icon {
    background: url(../asserts/icon/home-s.png) no-repeat;
    background-size: contain;
}

.worker-icon {
    background: url(../asserts/icon/home.png) no-repeat;
    background-size: contain;
}

.adm-tab-bar-item-active .worker-icon {
    background: url(../asserts/icon/home-s.png) no-repeat;
    background-size: contain;
}

.information-icon {
    background: url(../asserts/icon/home.png) no-repeat;
    background-size: contain;
}

.adm-tab-bar-item-active .information-icon {
    background: url(../asserts/icon/home-s.png) no-repeat;
    background-size: contain;
}


.factory-icon {
    background: url(../asserts/icon/home.png) no-repeat;
    background-size: contain;
}

.adm-tab-bar-item-active .factory-icon {
    background: url(../asserts/icon/home-s.png) no-repeat;
    background-size: contain;
}

.personal-icon {
    background: url(../asserts/icon/home.png) no-repeat;
    background-size: contain;
}

.adm-tab-bar-item-active .personal-icon {
    background: url(../asserts/icon/home-s.png) no-repeat;
    background-size: contain;
}


.worker .adm-list-item-content-main {
    padding: 6px 0px;
}

.project-list .zd,
.worker .zd {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 38px;
}



.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.flex-column {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.flex-align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.flex-1 {
    flex: 1;
}

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}



.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.flex-row-reverse-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}

.number-input .adm-input-element {
    text-align: center;
    width: auto;
}

.svg-wrapper svg {
    fill: #f00;
    width: 30px;
}

.h2 {
    font-size: 20px;
    font-weight: 500;
}

.react-pdf__Page__textContent {
    display: none;
}