.lawyer-index-page {
    background: #F7F8FA;
    padding: 1rem;
}

.lawyer-index-page .adm-list-body {
    background: #F7F8FA;
}

.lawyer-index-page .robot-item {
    background: #FFFFFF;
    border-radius: 0.8rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
}


.lawyer-index-page .robot-item .robot-headimg {
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 1rem;
}

.lawyer-index-page .robot-item .t1 {
    box-sizing: border-box;
    font-size: 1.3rem;
}

.lawyer-index-page .robot-item .t1 .t1-data {
    margin-top: 0.5rem;
}

.lawyer-index-page .robot-item .t1 .t1-tag {
    background: #F6F6F6;
    border-radius: 0.9rem;
    padding: 0.2rem 0.8rem;
    color: #3D445B;
    font-size: 1rem;
    margin-right: 0.6rem;
}


.lawyer-index-page .robot-item .t1 .t1-tag .s {
    font-size: 1.2rem;
    font-weight: 500;
    color: #10A37F;
}

.lawyer-index-page .robot-item .t2 {
    margin: 1.5rem 0 0 5.3rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 2rem;
}


.lawyer-index-page .robot-item .like-title {
    font-size: 1.3rem;
    color: #999999;

}

.lawyer-index-page .robot-item .t3 {
    margin: 1.25rem 0 0 4rem;

}