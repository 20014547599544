/* .lawyer-mine-lawyer-order-detail-page .adm-list-item.adm-form-item {
    background: #FFFFFF;
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
    margin-bottom: 0.5rem;
} */

.lawyer-mine-lawyer-order-detail-page .adm-list-item-content-main {
    font-size: 1.6rem;
    font-weight: 400;
    color: #1D2544;
    line-height: 2.25rem;
}

.lawyer-mine-lawyer-order-detail-page .adm-list-item.adm-form-item .adm-form-item-label {
    font-size: 1.6rem;
    font-weight: 400;
    color: #1D2544;
    line-height: 2.25rem;
}

.lawyer-mine-lawyer-order-detail-page .adm-list-item.adm-form-item .adm-form-item-child-inner {
    font-size: 1.3rem;
    font-weight: 400;
    color: #555B70;
    line-height: 1.85rem;
    letter-spacing: 1px;
    margin-top: 0.5rem;
}