.lawyer-navbar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    z-index: 100;
    font-size: 1.2rem;
    box-shadow: 0rem 0rem 0.2rem 0rem rgba(163, 163, 163, 0.5);
}

.lawyer-navbar .adm-tab-bar-item-title {
    font-size: 1.2rem;
    margin-top: 0.4rem;
}

.lawyer-navbar .navbar-icon {
    width: 2rem;
    height: 2rem;
}

.lawyer-navbar .adm-tab-bar-item-icon {
    width: 2rem;
    height: 2rem;
}


.lawyer-navbar .adm-tab-bar-item-active {
    color: #10A37F;
}

.lawyer-navbar .home-icon {
    background: url(/src/pages/lawyer/icon/home.png) no-repeat;
    background-size: contain;
}


.lawyer-navbar .adm-tab-bar-item-active .home-icon {
    background: url(/src/pages/lawyer/icon/home-s.png) no-repeat;
    background-size: contain;
}


.lawyer-navbar .chat-icon {
    background: url(/src/pages/lawyer/icon/chat.png) no-repeat;
    background-size: contain;
}

.lawyer-navbar .adm-tab-bar-item-active .chat-icon {
    background: url(/src/pages/lawyer/icon/chat-s.png) no-repeat;
    background-size: contain;
}


.lawyer-navbar .lawyer-icon {
    background: url(/src/pages/lawyer/icon/lawyer.png) no-repeat;
    background-size: contain;
}

.lawyer-navbar .adm-tab-bar-item-active .lawyer-icon {
    background: url(/src/pages/lawyer/icon/lawyer-s.png) no-repeat;
    background-size: contain;
}


.lawyer-navbar .mine-icon {
    background: url(/src/pages/lawyer/icon/mine.png) no-repeat;
    background-size: contain;
}

.lawyer-navbar .adm-tab-bar-item-active .mine-icon {
    background: url(/src/pages/lawyer/icon/mine-s.png) no-repeat;
    background-size: contain;
}