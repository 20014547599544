.lawyer-mine-lawyer-page {
    background: #F7F8FA;
    padding: 4rem 1.5rem 3rem 1.5rem;
    min-height: 100vh;
}

.lawyer-mine-lawyer-page .adm-tabs-header {
    border: 0px;
}

.lawyer-mine-lawyer-page .adm-tabs-tab-active {
    color: #10A37F;
}

.lawyer-mine-lawyer-page .adm-tabs-tab-line {
    background: #10A37F;
}

.lawyer-mine-lawyer-page .adm-tabs-content {
    padding: 0px;
}


.lawyer-mine-lawyer-page .adm-list {
    margin-top: 1rem;
    border-radius: 0.6rem;
}

.lawyer-mine-lawyer-page .adm-list-item-content {
    border-top: 0px;
}

/* 
.lawyer-mine-lawyer-page .lawyer-item {
    margin-bottom: 2.4rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
}

.lawyer-mine-lawyer-page .lawyer-item .t1 {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
}

.lawyer-mine-lawyer-page .lawyer-item .t1 .headimg {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: relative;
    top: -2rem;
}

.lawyer-mine-lawyer-page .lawyer-item .t1 .s1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5D5E5F;
    line-height: 1.65rem;
}


.lawyer-mine-lawyer-page .lawyer-item .t1 .b {
    font-size: 1.3rem;
    font-weight: 500;
    color: #111111;
    line-height: 1.55rem;
}

.lawyer-mine-lawyer-page .lawyer-item .t1 .s2 {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;
    font-weight: 500;
    color: #1D2544;
    line-height: 2.5rem;
    margin: 0.8rem;
}

.lawyer-mine-lawyer-page .lawyer-item .lawyer-name {
    font-size: 1.8rem;
    font-weight: 500;
    color: #1D2544;
    line-height: 2.5rem;
    margin: 0.8rem;
}


.lawyer-mine-lawyer-page .lawyer-item .company-name {
    background: #EAF3F1;
    border-radius: 0.95rem;
    font-size: 1rem;
    color: #10A37F;
    display: flex;
    align-items: center;
    line-height: 1.4rem;
    padding: 0px 0.8rem;
    margin-left: 0.8rem;
} */
/* 

.lawyer-mine-lawyer-page .lawyer-item .t2 {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
}

.lawyer-mine-lawyer-page .lawyer-item .t2 .price-wraper {
    font-size: 1.3rem;
    color: #FF5300;
}

.lawyer-mine-lawyer-page .lawyer-item .t2 .price {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.85rem;
}

.lawyer-mine-lawyer-page .lawyer-item .t2 .create-btn {
    width: 6rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border: 0.05rem solid #10A37F;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
} */

.lawyer-mine-lawyer-page .intro {
    background: #FFFFFF;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 1.7rem;
    margin-top: 1rem;
    padding: 1.7rem 1.5rem;
}

.lawyer-mine-lawyer-page .intro .title {
    font-size: 1.3rem;
    font-weight: 500;
    color: #333333;
    line-height: 1.85rem;
    margin-bottom: 1.5rem;
}

.lawyer-mine-lawyer-page .laywer-account-base {
    padding-bottom: 2rem;
    height: calc(100vh - 20rem);
    overflow-y: auto;
}

.lawyer-mine-lawyer-page .fixed-btn-wrapper {
    padding: 2rem 0;
}


.lawyer-mine-lawyer-page .laywer-protocol {
    padding-bottom: 2rem;
    height: calc(100vh - 20rem);
    overflow-y: auto;
}


.lawyer-mine-lawyer-page .protocol-item {
    background: #FFFFFF;
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
    margin-top: 1rem;
    padding: 1.5rem;
}

.lawyer-mine-lawyer-page .protocol-item .t2 {
    border-bottom: 1px solid #EEEEEE;
    padding: 1rem 0rem 1rem 0rem;
}

.lawyer-mine-lawyer-page .protocol-item .t3 {
    border-top: 1px solid #EEEEEE;
    padding-top: 1rem;
}

.lawyer-mine-lawyer-page .protocol-item .t3 .price-wraper {
    font-size: 1.6rem;
    color: #FF5300;
}

.lawyer-mine-lawyer-page .protocol-item .t3 .price {
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.85rem;
}

.lawyer-mine-lawyer-page .protocol-item .t3 .status-open {
    /* width: 6rem;
    height: 2.4rem; */
    border-radius: 1.2rem;
    border: 0.05rem solid #10A37F;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
    padding: 0.2rem 1rem;
}


.lawyer-mine-lawyer-page .protocol-item .t3 .status-close {
    /* width: 6rem; */
    /* height: 2.4rem; */
    border-radius: 1.2rem;
    border: 0.05rem solid #FF9900;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #FF9900;
    padding: 0.2rem 1rem;
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAF4F2;
    border-radius: 0.8rem;
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon svg {
    width: 2rem;
    height: 2rem;
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon .st1 {
    color: #10A37F;
    fill: #10A37F
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon .st2 {
    fill: #2DB291;
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon .st3 {
    fill: #FFFFFF;
}

.lawyer-mine-lawyer-page .protocol-item .protocol-icon .st4 {
    fill: #10A37F;
}

.lawyer-mine-lawyer-page .laywer-mine-laywer-account-news {
    height: calc(100vh - 25rem);
    overflow: scroll;
}

.lawyer-mine-lawyer-page .add-icon {
    position: absolute;
    right: 1.5rem;
    bottom: 3.5rem;
}

.lawyer-mine-lawyer-page .adm-input-element {
    font-size: 1.4rem;
}

.lawyer-mine-lawyer-page .adm-text-area-element {
    font-size: 1.4rem;
}

.lawyer-page .laywer-mine-laywer-account-news .image-wrapper {
    margin-top: 2rem;
    /* padding: 0 1.5rem; */
}


.lawyer-page .laywer-mine-laywer-account-news .image-item {
    /* background: #FFFFFF; */
    /* box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(171, 178, 184, 0.5); */
    border-radius: 1rem;
    /* margin: 1rem 3rem 0 3rem; */
    text-align: center;
}

.lawyer-page .laywer-mine-laywer-account-news .adm-swiper-slide {
    margin-right: 1.5rem;
    /* box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 1rem; */
}


.lawyer-page .laywer-mine-laywer-account-news .image-item img {
    width: 100%;
    height: 16rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.lawyer-page .laywer-mine-laywer-account-news .image-item .image-title {
    padding: 1rem 0;
    background-color: #ffffff;
    /* box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(171, 178, 184, 0.5);     */
    border-radius: 1rem;
}


.lawyer-page .laywer-mine-laywer-account-news .news-title {
    font-size: 1.3rem;
    font-weight: 400;
    color: #1B2443;
    line-height: 1.85rem;
    margin-top: 2rem;
}

.lawyer-page .laywer-mine-laywer-account-news .news-wrapper {
    background-color: #ffffff;
    padding: 2rem 1.5rem;
}

.lawyer-page .laywer-mine-laywer-account-news .news-item-title {
    background: #F7F7F7;
    border-radius: 0.7rem;
    padding: 0.6rem 2rem;
}

.lawyer-page .laywer-mine-laywer-account-news .news-item-time {
    font-size: 1.1rem;
    color: #7B8193;
    line-height: 1.5rem;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 1rem;
}

.lawyer-page .laywer-mine-laywer-account-news .news-item-time .circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #63D9BC;
    position: relative;
    left: -1.5rem;
    top: 1.2rem;
}

.lawyer-page .laywer-mine-laywer-account-news .news-item-time .line {
    width: 0.1rem;
    height: 4rem;
    background: #d2d4d9;
    position: absolute;
    left: -1.2rem;
    top: 3rem;
}

.lawyer-page .laywer-mine-laywer-account-news .adm-list-item-content-main {
    padding-top: 0
}


.lawyer-page .laywer-mine-laywer-account-news .add-btns {
    background: #FFFFFF;
    box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(134, 134, 134, 0.5);
    border-radius: 0.8rem;
    padding: 1rem 4rem 1rem 2rem;
    position: absolute;
    bottom: 3rem;
    right: 6em;
}


.lawyer-mine-lawyer-page .mask-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000055;
}

.customIndicator {
    position: absolute;
    top: 6px;
    right: 6px;
    background: rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    color: #ffffff;
    border-radius: 4px;
    user-select: none;
}