.lawyer-chat-detail-page {
    background: #F7F8FA;
    padding: 1rem 1.5rem;
}


.lawyer-chat-detail-page .robot-item {
    background: #FFFFFF;
    border-radius: 0.6rem;
    margin-bottom: 1rem;
}


.lawyer-chat-detail-page .robot-item .robot-headimg {
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 1rem;
}

.lawyer-chat-detail-page .robot-item .t1 {
    box-sizing: border-box;
    font-size: 1.3rem;
    padding: 1.5rem 1.5rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
}

.lawyer-chat-detail-page .robot-item .t1 .t1-data {
    margin-top: 0.2rem;
}

.lawyer-chat-detail-page .robot-item .t1 .t1-tag {
    background: #F6F6F6;
    border-radius: 0.9rem;
    padding: 0.2rem 0.8rem;
    color: #3D445B;
    font-size: 1rem;
    margin-right: 0.6rem;
}


.lawyer-chat-detail-page .robot-item .t1 .t1-tag .s {
    font-size: 1.2rem;
    font-weight: 500;
    color: #10A37F;
}


.lawyer-chat-detail-page .robot-item .t3 {
    font-size: 1.3rem;
    color: #999999;
    padding: 0.8rem 2rem;
    background-color: #ffffff !important;
    text-align: right;
    flex-direction: row-reverse;
}




.lawyer-chat-detail-page .chat-content {
    height: calc(100vh - 15rem);
    overflow: auto;
    padding-bottom: 5rem;
    box-sizing: border-box;
}

.lawyer-chat-detail-page .chat-send-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    padding: 0.6rem 0 1rem 0;
    border-top: 1px solid #F2F6FA;
}

.lawyer-chat-detail-page .chat-send-box .chat-send-input {
    flex: 1;
    height: auto;
    border: 1px solid #EAEBF0;
    border-radius: 5px;
    margin: 10px;
    padding: 0px 5px 0px 10px;
    box-sizing: border-box;
    font-size: 1.4rem;
}

.lawyer-chat-detail-page .chat-send-box .chat-send-input textarea {
    padding: 8px 0px;
    overflow: hidden;
}

.lawyer-chat-detail-page .chat-send-box .btn-send {
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    background-color: #4A90E2;
    color: #ffffff;
    float: right;
    padding: 0px;
    border: none;
    margin-right: 5px;
}


.lawyer-chat-detail-page .question-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #00000055;
}

.lawyer-chat-detail-page .question-bg .question-box {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    box-sizing: border-box;
    border-bottom: 1px solid #ecebeb;
    max-height: 80vh;
}


.lawyer-chat-detail-page .question-bg .question-box .question-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ecebeb;
}


.lawyer-chat-detail-page .question-bg .question-box .icon-down {
    position: absolute;
    right: 10px;
    top: 10px;
}


.lawyer-chat-detail-page .question-bg .question-box .question-list {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: calc(80vh - 80px);
    overflow-y: auto;

}

.lawyer-chat-detail-page .question-bg .question-box .question-list .question-item span {
    border: 0.5px solid #C4C4C4;
    border-radius: 10px;
    /* height: 30px; */
    line-height: 30px;
    display: inline-block;
    margin-bottom: 10px;
    padding: 0px 10px;
}


.lawyer-chat-detail-page .tool-box {
    /* position: absolute; */
    bottom: 0px;
    width: 100%;
    background: #F7F8FA;
    border-radius: 10px 10px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 2rem 0 2rem;
    flex-direction: row;
    z-index: 1000;
    border-bottom: 1px solid #ecebeb;
}


.lawyer-chat-detail-page .tool-box .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    margin-bottom: 2rem;
    color: #1A1A1A;
}


.lawyer-chat-detail-page .tool-box .tool-item .tool-icon {
    width: 5.7rem;
    height: 5.7rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;
}

.lawyer-chat-detail-page .chat-msg-item {
    align-items: start;
    margin-bottom: 1rem;
}


.lawyer-chat-detail-page .chat-msg-item .chat-msg-item-headimg {
    width: 4rem;
    height: 4rem;
    border-radius: 0.8rem;
}

.lawyer-chat-detail-page .chat-content .chat-msg {
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 0.5rem;
    white-space: pre-wrap;
    max-width: calc(100% - 2rem);
    font-size: 1.4rem;
    margin-left: 1rem;
}

.lawyer-chat-detail-page .chat-content .flex-row-reverse-right .chat-msg {
    background-color: #10A37F;
    margin-right: 1rem;
    color: #ffffff;
    margin-left: 0;
}


.lawyer-chat-detail-page .tool-icon-img {
    width: 3rem;
    height: 3rem;
}

.lawyer-chat-detail-page .adm-text-area-element {
    font-size: 1.4rem;
}