.lawyer-login-page {
    background: linear-gradient(180deg, #EEFFF9 0%, #F5FCFA 42%, #FFFFFF 100%);
    height: 100vh;
    padding: 0 3rem;
}

.lawyer-login-page .logo-bg {
    padding: 6.4rem 0px;
}

.lawyer-login-page .adm-list-body {
    background-color: inherit
}

.lawyer-login-page .adm-list-item {
    padding-left: 0px;
    border-radius: 2rem;

}

.lawyer-login-page .adm-form .adm-form-footer {
    padding: 0px;
    margin-top: 2rem;
}

.lawyer-login-page .btn-register,
.lawyer-login-page .btn-login-phone {
    font-size: 1.3rem;
    font-weight: 400;
    color: #474747;
    line-height: 1.8rem;
    padding: 1.5rem 1.5rem 0px 1.5rem
}


.lawyer-login-page .adm-list-item-content-main {
    padding: 1rem 0;
}

.lawyer-login-page .adm-list-item-content {
    border: 0.05rem solid #10A37F;
    border-radius: 2rem;
    padding: 0px 1.8rem;
}

.lawyer-page .aggrement-privacy {
    text-align: center;
    margin-top: 2rem;
}


.lawyer-page .aggrement-privacy .s2 {
    color: #10A37F;
}

.lawyer-page .aggrement-privacy .adm-radio-content {
    font-size: 1.2rem;
    font-weight: 400;
    color: #959595;
}

.lawyer-page .aggrement-privacy .adm-radio {
    --icon-size: 1.4rem;
}