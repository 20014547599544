.lawyer-lawyer-page {
    background: #F7F8FA;
    padding: 3rem 1.5rem;
    height: 100vh;
}

.lawyer-lawyer-page .adm-list-body {
    background-color: inherit;
    border: 0px;
    overflow: visible;
}

.lawyer-lawyer-page .lawyer-item {
    margin-bottom: 3.2rem !important;
}

.lawyer-lawyer-page .lawyer-item {
    /* border-radius: 0.6rem 0.6rem 0rem 0rem; */
    margin-bottom: 2.4rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.24);
    border-radius: 1rem;
}

.lawyer-lawyer-page .lawyer-item .t1 {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
}

.lawyer-lawyer-page .lawyer-item .t1 .headimg {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: relative;
    top: -2rem;
}

.lawyer-lawyer-page .lawyer-item .t1 .s1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5D5E5F;
    line-height: 1.65rem;
}



/* .lawyer-lawyer-page .lawyer-item .t1 .b {
    font-size: 1.3rem;
    font-weight: 500;
    color: #111111;
    line-height: 1.55rem;
} */

.lawyer-lawyer-page .lawyer-item .t1 .s2 {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;
    font-weight: 500;
    color: #1D2544;
    line-height: 2.5rem;
    /* margin: 0.8rem;   */

}

.lawyer-lawyer-page .lawyer-item .t1 .s2 .s {
    /* background: #EAF3F1; */
    border-radius: 0.95rem;
    font-size: 1rem;
    color: #10A37F;
    display: flex;
    align-items: center;
    line-height: 2.0rem;
    padding: 0.25px 1rem;
    margin-left: 0.8rem;
}

.lawyer-lawyer-page .lawyer-item .t1 .s2 .title-date {
    font-size: 1.2rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.65rem;
    margin-left: 0.3rem;
}


.lawyer-lawyer-page .lawyer-item .t2 {
    font-size: 1.3rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 1.85rem;
    /* background: #FFFFFF; */
    padding: 0.5rem 1.5rem;
}


.lawyer-lawyer-page .lawyer-item .t3 {
    display: flex;
    /* background: #FFFFFF; */
    padding: 1rem 1.5rem 2rem 1.5rem;
}



.lawyer-lawyer-page .lawyer-item .t3 .tag {
    background: #F6F6F6;
    border-radius: 0.9rem;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    color: #3D445B;
    line-height: 1.4rem;
    margin-right: 0.5rem;
}