.mine-protocol-detail-page {
    min-height: 100vh;
    padding: 3.5rem 1.5rem 0rem 1.5rem;
}


.mine-protocol-detail-page .adm-list-item-content {
    border-top: 0px;
    margin-top: 1rem;
}

.mine-protocol-detail-page .lawyer-item {
    margin-bottom: 2.4rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
    box-shadow: 0rem 0.2rem 0.2rem 0rem rgba(171, 178, 184, 0.5);
    border-radius: 0.6rem;
}

.mine-protocol-detail-page .lawyer-item .t1 {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    background: linear-gradient(103deg, #F3FDFB 0%, #FFFFFF 51%, #F3FEFC 100%);
}

.mine-protocol-detail-page .lawyer-item .t1 .headimg {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: relative;
    top: -2rem;
}

.mine-protocol-detail-page .lawyer-item .t1 .s1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5D5E5F;
    line-height: 1.65rem;
}



.mine-protocol-detail-page .lawyer-item .t1 .b {
    font-size: 1.3rem;
    font-weight: 500;
    color: #111111;
    line-height: 1.55rem;
}

.mine-protocol-detail-page .lawyer-item .t1 .s2 {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;
    font-weight: 500;
    color: #1D2544;
    line-height: 2.5rem;
    margin: 0.8rem;

}

.mine-protocol-detail-page .lawyer-item .t1 .s2 .s {
    background: #EAF3F1;
    border-radius: 0.95rem;
    font-size: 1rem;
    color: #10A37F;
    display: flex;
    align-items: center;
    line-height: 1.4rem;
    padding: 0px 0.8rem;
    margin-left: 0.8rem;
}


.mine-protocol-detail-page .lawyer-item .t2 {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
}

.mine-protocol-detail-page .lawyer-item .t2 .price-wraper {
    font-size: 1.3rem;
    color: #FF5300;
}

.mine-protocol-detail-page .lawyer-item .t2 .price {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.85rem;
}

.mine-protocol-detail-page .lawyer-item .t2 .create-btn {
    width: 6rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border: 0.05rem solid #10A37F;
    background-color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
}

.mine-protocol-detail-page .protocol-tip {
    font-size: 1.2rem;
    font-weight: 400;
    color: #10A37F;
    line-height: 1.65rem;
}


.mine-protocol-detail-page .adm-form-item-label {
    font-size: 1.6rem;
    font-weight: 400;
    color: #1D2544;
    line-height: 2.25rem;
}

.mine-protocol-detail-page .adm-input-element {
    font-size: 1.3rem;
    font-weight: 400;
    color: #555B70;
    line-height: 1.85rem;
    letter-spacing: 1px;
}

.mine-protocol-detail-page .protocol-content {
    margin-bottom: 80px;
}

.mine-protocol-detail-page .protocol-content .protocol-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem 2rem 4rem 2rem;
    background-color: #ffffff;
    box-sizing: border-box;
}