.lawyer-mine-page {
    padding: 1.5rem;
    height: 100vh;
    background: linear-gradient(180deg, #10A37F 0%, #FFFFFF 51%, #F3FEFC 100%);
}

.lawyer-mine-page .mine-head {
    display: flex;
    align-items: center;
    padding: 2.5rem 0 4rem 0;
}

.lawyer-mine-page .mine-head .headimg {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 3rem;
}

.lawyer-mine-page .myname {
    margin-left: 1.2rem;
}

.lawyer-mine-page .mine-head .name {
    font-size: 1.7rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2.4rem;
    letter-spacing: 2px;
    margin-bottom: 0.5rem;
}


.lawyer-mine-page .mine-head .phone {
    background: #EFFFFB;
    border-radius: 0.85rem;
    font-size: 1rem;
    font-weight: 400;
    color: #10A37F;
    line-height: 1.4rem;
    letter-spacing: 1px;
    padding: 0 0.6rem;
}


.lawyer-mine-page .icon-title {
    font-size: 1.2rem;
}

.lawyer-mine-page .mine-service {
    margin-top: 1rem;
}


.lawyer-mine-page .lawyer-service {
    margin-top: 1rem;

}


.lawyer-mine-page .lawyer-service .lawyer-service-icon {
    color: #10A37F;
    font-size: 2.6rem;
}